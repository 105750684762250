import logo from './logo.svg';
import './App.css';
import ui from "./images/ui.jpg"

function App() {
    return (
        <div className="App">
            {/*<header className="App-header">*/}
                {/*<img src={logo} className="App-logo" alt="logo" />*/}
            {/*</header>*/}
            <div>
                <img src={ui} alt="作品集" style={{overflowY:"inherit",width:"100%",height:"100%"}}/>
            </div>
        </div>
    );
}

export default App;
